"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventLog = void 0;
var Lister_1 = require("../Lister");
var Page_1 = require("../Page");
var EventLogItem_1 = require("./EventLogItem");
var EventLog = /** @class */ (function () {
    function EventLog(client, seatsioClient) {
        this.client = client;
        this.seatsioClient = seatsioClient;
    }
    EventLog.prototype.listAll = function () {
        return this.iterator().all();
    };
    EventLog.prototype.listFirstPage = function (pageSize) {
        if (pageSize === void 0) { pageSize = null; }
        return this.iterator().firstPage(null, pageSize);
    };
    EventLog.prototype.listPageAfter = function (afterId, pageSize) {
        if (pageSize === void 0) { pageSize = null; }
        return this.iterator().pageAfter(afterId, null, pageSize);
    };
    EventLog.prototype.listPageBefore = function (beforeId, pageSize) {
        if (pageSize === void 0) { pageSize = null; }
        return this.iterator().pageBefore(beforeId, null, pageSize);
    };
    EventLog.prototype.iterator = function () {
        return new Lister_1.Lister('/event-log', this.client, 'eventLogItems', function (data) {
            var eventLogItems = data.items.map(function (json) { return new EventLogItem_1.EventLogItem(json); });
            return new Page_1.Page(eventLogItems, data.next_page_starts_after, data.previous_page_ends_before);
        });
    };
    return EventLog;
}());
exports.EventLog = EventLog;
