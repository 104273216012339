"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BestAvailableParams = void 0;
var BestAvailableParams = /** @class */ (function () {
    function BestAvailableParams() {
    }
    BestAvailableParams.prototype.withNumber = function (number) {
        this.number = number;
        return this;
    };
    BestAvailableParams.prototype.withCategories = function (categories) {
        this.categories = categories;
        return this;
    };
    BestAvailableParams.prototype.withZone = function (zone) {
        this.zone = zone;
        return this;
    };
    BestAvailableParams.prototype.withExtraData = function (extraData) {
        this.extraData = extraData;
        return this;
    };
    BestAvailableParams.prototype.withTicketTypes = function (ticketTypes) {
        this.ticketTypes = ticketTypes;
        return this;
    };
    BestAvailableParams.prototype.withTryToPreventOrphanSeats = function (tryToPreventOrphanSeats) {
        this.tryToPreventOrphanSeats = tryToPreventOrphanSeats;
        return this;
    };
    BestAvailableParams.prototype.withAccessibleSeats = function (accessibleSeats) {
        this.accessibleSeats = accessibleSeats;
        return this;
    };
    return BestAvailableParams;
}());
exports.BestAvailableParams = BestAvailableParams;
