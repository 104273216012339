"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.User = void 0;
var User = /** @class */ (function () {
    function User(json) {
        this.id = json.id;
        this.email = json.email;
        this.name = json.name;
        this.secretKey = json.secretKey;
        this.designerKey = json.designerKey;
        this.role = json.role;
        this.createdOn = json.createdOn ? new Date(json.createdOn) : null;
        this.isActive = json.isActive;
        this.status = json.status;
        this.workspaces = json.workspaces;
        this.nonAdminHasAccessToAllWorkspaces = json.nonAdminHasAccessToAllWorkspaces;
    }
    return User;
}());
exports.User = User;
