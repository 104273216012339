"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.errorResponseHandler = errorResponseHandler;
function errorResponseHandler(e) {
    return new Promise(function (resolve, reject) {
        if (typeof e.response !== 'undefined') {
            if (typeof e.response.data !== 'undefined' && e.response.data) {
                reject(e.response.data);
            }
            else if (typeof e.response.statusText !== 'undefined' && e.response.statusText) {
                reject("".concat(e.config.method, " ").concat(e.config.url, " resulted in ").concat(e.response.status, " ").concat(e.response.statusText, " error")); // eslint-disable-line
            }
        }
        else {
            reject(e);
        }
    });
}
