"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventLogItem = void 0;
var EventLogItem = /** @class */ (function () {
    function EventLogItem(json) {
        this.id = json.id;
        this.type = json.type;
        this.timestamp = new Date(json.date);
        this.data = json.data;
    }
    return EventLogItem;
}());
exports.EventLogItem = EventLogItem;
