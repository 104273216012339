"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChartListParams = void 0;
var ChartListParams = /** @class */ (function () {
    function ChartListParams() {
        this.expandEvents = false;
        this.expandValidation = false;
        this.expandVenueType = false;
        this.expandZones = false;
    }
    ChartListParams.prototype.withFilter = function (filter) {
        this.filter = filter;
        return this;
    };
    ChartListParams.prototype.withTag = function (tag) {
        this.tag = tag;
        return this;
    };
    ChartListParams.prototype.withExpandEvents = function (expandEvents) {
        this.expandEvents = expandEvents;
        return this;
    };
    ChartListParams.prototype.withEventsLimit = function (eventsLimit) {
        this.eventsLimit = eventsLimit;
        return this;
    };
    // @deprecated use withExpandValidation instead
    ChartListParams.prototype.withValidation = function (validation) {
        return this.withExpandValidation(validation);
    };
    ChartListParams.prototype.withExpandValidation = function (expandValidation) {
        this.expandValidation = expandValidation;
        return this;
    };
    ChartListParams.prototype.withExpandVenueType = function (expandVenueType) {
        this.expandVenueType = expandVenueType;
        return this;
    };
    ChartListParams.prototype.withExpandZones = function (expandZones) {
        this.expandZones = expandZones;
        return this;
    };
    ChartListParams.prototype.serialize = function () {
        return {
            tag: this.tag,
            expand: this.expandParams(),
            filter: this.filter,
            eventsLimit: this.eventsLimit
        };
    };
    ChartListParams.prototype.expandParams = function () {
        var expandParams = [];
        if (this.expandEvents) {
            expandParams.push('events');
        }
        if (this.expandValidation) {
            expandParams.push('validation');
        }
        if (this.expandVenueType) {
            expandParams.push('venueType');
        }
        if (this.expandZones) {
            expandParams.push('zones');
        }
        return expandParams;
    };
    return ChartListParams;
}());
exports.ChartListParams = ChartListParams;
