"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Zone = void 0;
var Zone = /** @class */ (function () {
    function Zone(key, label) {
        this.key = key;
        this.label = label;
    }
    Zone.prototype.getKey = function () {
        return this.key;
    };
    Zone.prototype.getLabel = function () {
        return this.label;
    };
    Zone.fromJson = function (zone) {
        return new Zone(zone.key, zone.label);
    };
    return Zone;
}());
exports.Zone = Zone;
