"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

var zeroWidthSpace = "\u200B";

var isAndroid = function isAndroid() {
    return (/(android)/i.test(navigator.userAgent)
    );
};

/**
 * Finds the selection path given the node, editor, and selection offset
 *
 * @param node - the DOM Node we're searching for
 * @param editor - a reference to the editor webcomponent
 * @param offset - the selection path offset if one exists
 * @returns {null|*[]} an array of indexes from the editor document root to the node, otherwise null.
 */
var _getSelectionPath = function _getSelectionPath(node, editor, offset) {
    var originalNode = node;
    if (!node) {
        return null;
    }

    var path = [];
    try {
        while (node && node.tagName !== "BODY") {
            path.push(node);
            if (node === editor) {
                break;
            }
            node = node.parentNode;
        }

        if (path[path.length - 1] !== editor) {
            return null;
        }

        var indexPath = [];
        for (var i = 0; i < path.length - 1; i += 1) {
            var child = path[i];
            var parent = path[i + 1];

            var index = 0;
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = parent.childNodes[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var childNode = _step.value;

                    if (childNode === child) {
                        break;
                    }
                    index += 1;
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator.return) {
                        _iterator.return();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }

            indexPath.unshift(index);
        }

        if (originalNode.nodeType === Node.ELEMENT_NODE && offset > 0) {
            indexPath.push(offset - 1);
        } else if (originalNode.nodeType === Node.ELEMENT_NODE && originalNode.childNodes[0]) {
            indexPath.push(0);
        }

        if (indexPath.length <= 2) {
            return null;
        }

        // Drop the first two nodes
        indexPath.shift();
        indexPath.shift();

        return indexPath.slice();
    } catch (e) {
        // Sometimes we can get errors from trying to access properties like "tagName".  In that
        // just return null.
        return null;
    }
};

/**
 * Finds a node given a path.  Returns null if no node exists
 * @param path - an array of indexes or null
 * @param editor - a reference to the editor webcomponent
 * @returns {null|ChildNode|*} Returns the node this path refers to, null otherwise.
 */
var _findNodeFromPath = function _findNodeFromPath(path, editor) {
    if (!path) {
        return null;
    }

    if (typeof path === "string") {
        path = path.split(":").map(function (v) {
            return Number(v);
        });
    }

    var node = editor;
    var newPath = [0, 0].concat(_toConsumableArray(path));
    while (newPath.length && node) {
        var index = newPath.shift();
        node = node.childNodes && node.childNodes[index];
    }

    return node || null;
};

/**
 * Helper method to account for zeroWidthSpace and selection offsets that exceed the actual node
 * length.
 * @param node - a reference to the node this offset refers to
 * @param offset - the offset in question
 * @returns {number|*} the new offset
 */
var adjustOffsetReverse = function adjustOffsetReverse(node, offset) {
    if (node.nodeType === Node.TEXT_NODE && node.nodeValue === zeroWidthSpace) {
        return 1;
    }
    if (node.nodeType === Node.TEXT_NODE && offset > node.nodeValue.length) {
        return node.nodeValue.length;
    }
    return offset;
};

/**
 * Helper method to account for zeroWidthSpace and simplifying boundary selection by selecting
 * the child node and setting the offset to 0.  Note that this sometimes leads to invalid
 * selections, but I think it's better than the alternative of trying to derive the boundary logic
 * in Elm.
 *
 * @param node - a reference to the node this offset refers to
 * @param offset - the offset in question
 * @returns {number|*} the new offset
 */
var adjustOffset = function adjustOffset(node, offset) {
    if (node.nodeType === Node.TEXT_NODE && node.nodeValue === zeroWidthSpace) {
        return 0;
    }

    if (node.nodeType === Node.ELEMENT_NODE) {
        var childNode = node.childNodes[offset - 1];
        if (childNode && childNode.nodeType === Node.TEXT_NODE) {
            return (childNode.nodeValue || "").length;
        }
    }

    return offset;
};

/**
 * SelectionState is a webcomponent that syncs the editor's selection state with the selection
 * API.
 */

var SelectionState = function (_HTMLElement) {
    _inherits(SelectionState, _HTMLElement);

    _createClass(SelectionState, null, [{
        key: "observedAttributes",
        get: function get() {
            return ["selection"];
        }
    }]);

    function SelectionState() {
        _classCallCheck(this, SelectionState);

        var _this = _possibleConstructorReturn(this, (SelectionState.__proto__ || Object.getPrototypeOf(SelectionState)).call(this));

        _this.selectionChange = _this.selectionChange.bind(_this);
        return _this;
    }

    _createClass(SelectionState, [{
        key: "attributeChangedCallback",
        value: function attributeChangedCallback(name, oldValue, newValue) {
            if (name !== "selection") {
                return;
            }
            var selectionObj = {};
            var _iteratorNormalCompletion2 = true;
            var _didIteratorError2 = false;
            var _iteratorError2 = undefined;

            try {
                for (var _iterator2 = newValue.split(",")[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                    var pair = _step2.value;

                    var splitPair = pair.split("=");
                    if (splitPair.length === 2) {
                        selectionObj[splitPair[0]] = splitPair[1];
                    }
                }
            } catch (err) {
                _didIteratorError2 = true;
                _iteratorError2 = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion2 && _iterator2.return) {
                        _iterator2.return();
                    }
                } finally {
                    if (_didIteratorError2) {
                        throw _iteratorError2;
                    }
                }
            }

            var focusOffset = Number(selectionObj["focus-offset"]);
            var focusNode = this.findNodeFromPath(selectionObj["focus-node"]);
            var anchorOffset = Number(selectionObj["anchor-offset"]);
            var anchorNode = this.findNodeFromPath(selectionObj["anchor-node"]);

            if (focusNode && anchorNode) {
                var sel = document.getSelection();

                anchorOffset = adjustOffsetReverse(anchorNode, anchorOffset);
                focusOffset = adjustOffsetReverse(focusNode, focusOffset);
                try {
                    sel.setBaseAndExtent(anchorNode, anchorOffset, focusNode, focusOffset);
                } catch (e) {
                    // ignore selection errors
                }
            }
        }
    }, {
        key: "connectedCallback",
        value: function connectedCallback() {
            document.addEventListener("selectionchange", this.selectionChange);
        }
    }, {
        key: "disconnectedCallback",
        value: function disconnectedCallback() {
            document.removeEventListener("selectionchange", this.selectionChange);
        }
    }, {
        key: "getSelectionPath",
        value: function getSelectionPath(node, offset) {
            return _getSelectionPath(node, this.parentNode, offset);
        }
    }, {
        key: "findNodeFromPath",
        value: function findNodeFromPath(path) {
            return _findNodeFromPath(path, this.parentNode);
        }
    }, {
        key: "getSelectionObject",
        value: function getSelectionObject() {
            var selection = { "selectionExists": false };
            var selectionObj = getSelection();
            if (!selectionObj) {
                return selection;
            }
            var anchorPath = this.getSelectionPath(selectionObj.anchorNode, selectionObj.anchorOffset);
            var focusPath = this.getSelectionPath(selectionObj.focusNode, selectionObj.focusOffset);
            if (!anchorPath || !focusPath) {
                return selection;
            }
            var anchorOffset = adjustOffset(selectionObj.anchorNode, selectionObj.anchorOffset);
            var focusOffset = adjustOffset(selectionObj.focusNode, selectionObj.focusOffset);
            return {
                "selectionExists": true,
                "anchorOffset": anchorOffset,
                "focusOffset": focusOffset,
                "anchorNode": anchorPath,
                "focusNode": focusPath
            };
        }
    }, {
        key: "selectionChange",
        value: function selectionChange(e) {
            var selection = this.getSelectionObject(e);
            var event = new CustomEvent("editorselectionchange", { detail: selection });
            this.parentNode.dispatchEvent(event);
        }
    }]);

    return SelectionState;
}(HTMLElement);

/**
 * ElmEditor is the top level webcomponent responsible for enabling web APIs like clipboard
 * and mutation observers to be visible in Elm.  It uses custom events which are handled
 * by Elm event listeners.
 */


var ElmEditor = function (_HTMLElement2) {
    _inherits(ElmEditor, _HTMLElement2);

    _createClass(ElmEditor, [{
        key: "compositionStart",
        value: function compositionStart() {
            var _this3 = this;

            this.composing = true;

            // Sometimes Android never fires compositionend... so we need to make sure it gets called
            // eventually.
            if (isAndroid()) {
                if (this.lastCompositionTimeout) {
                    clearTimeout(this.lastCompositionTimeout);
                }
                var lastCompositionTimeout = setTimeout(function () {
                    if (_this3.composing && lastCompositionTimeout === _this3.lastCompositionTimeout) {
                        _this3.composing = false;
                        var newEvent = new CustomEvent("editorcompositionend", {
                            detail: {}
                        });
                        _this3.dispatchEvent(newEvent);
                    }
                }, 5000);
                this.lastCompositionTimeout = lastCompositionTimeout;
            }
        }
    }, {
        key: "compositionEnd",
        value: function compositionEnd() {
            var _this4 = this;

            this.composing = false;
            // Use a custom composition end function since in some browsers, it gets fired before
            // the last keydown event occurs.
            setTimeout(function () {
                if (!_this4.composing) {
                    var newEvent = new CustomEvent("editorcompositionend", {
                        detail: {}
                    });
                    _this4.dispatchEvent(newEvent);
                }
            }, 0);
        }
    }]);

    function ElmEditor() {
        _classCallCheck(this, ElmEditor);

        var _this2 = _possibleConstructorReturn(this, (ElmEditor.__proto__ || Object.getPrototypeOf(ElmEditor)).call(this));

        _this2.mutationObserverCallback = _this2.mutationObserverCallback.bind(_this2);
        _this2.pasteCallback = _this2.pasteCallback.bind(_this2);
        _this2._observer = new MutationObserver(_this2.mutationObserverCallback);
        _this2.addEventListener("paste", _this2.pasteCallback);
        _this2.addEventListener("compositionstart", _this2.compositionStart.bind(_this2));
        _this2.addEventListener("compositionend", _this2.compositionEnd.bind(_this2));
        _this2.dispatchInit = _this2.dispatchInit.bind(_this2);

        return _this2;
    }

    _createClass(ElmEditor, [{
        key: "connectedCallback",
        value: function connectedCallback() {
            this._observer.observe(this, {
                characterDataOldValue: true,
                attributeOldValue: false,
                attributes: false,
                childList: true,
                subtree: true,
                characterData: true
            });
            this.initInterval = setInterval(this.dispatchInit, 1000);
        }
    }, {
        key: "disconnectedCallback",
        value: function disconnectedCallback() {
            this._observer.disconnect();
        }
    }, {
        key: "pasteCallback",
        value: function pasteCallback(e) {
            e.preventDefault();

            var clipboardData = e.clipboardData || window.clipboardData;
            var text = clipboardData.getData('text') || "";
            var html = clipboardData.getData('text/html') || "";
            var newEvent = new CustomEvent("pastewithdata", {
                detail: {
                    text: text,
                    html: html
                }
            });
            this.dispatchEvent(newEvent);
        }

        /**
         * Returns a list of selection path and text if all the mutations are characterData.  Otherwise
         * returns null.
         */

    }, {
        key: "characterDataMutations",
        value: function characterDataMutations(mutationsList) {
            if (!mutationsList) {
                return null;
            }

            var mutations = [];
            var _iteratorNormalCompletion3 = true;
            var _didIteratorError3 = false;
            var _iteratorError3 = undefined;

            try {
                for (var _iterator3 = mutationsList[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
                    var mutation = _step3.value;

                    if (mutation.type !== "characterData") {
                        return null;
                    }
                    mutations.push({
                        path: _getSelectionPath(mutation.target, this, 0),
                        text: mutation.target.nodeValue
                    });
                }
            } catch (err) {
                _didIteratorError3 = true;
                _iteratorError3 = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion3 && _iterator3.return) {
                        _iterator3.return();
                    }
                } finally {
                    if (_didIteratorError3) {
                        throw _iteratorError3;
                    }
                }
            }

            return mutations;
        }
    }, {
        key: "mutationObserverCallback",
        value: function mutationObserverCallback(mutationsList, _) {
            var element = this.querySelector('[data-rte-main="true"]');
            var selection = this.childNodes[1].getSelectionObject();

            var characterDataMutations = this.characterDataMutations(mutationsList);
            var event = new CustomEvent("editorchange", {
                detail: {
                    root: element,
                    selection: selection,
                    isComposing: this.composing,
                    characterDataMutations: characterDataMutations,
                    timestamp: new Date().getTime()
                }
            });
            this.dispatchEvent(event);
        }
    }, {
        key: "dispatchInit",
        value: function dispatchInit() {
            if (!this.isConnected) {
                return;
            }
            var isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
            var event = new CustomEvent("editorinit", {
                detail: {
                    shortKey: isMacLike ? "Meta" : "Control"
                }
            });
            this.dispatchEvent(event);
            clearInterval(this.initInterval);
        }
    }]);

    return ElmEditor;
}(HTMLElement);

customElements.define('elm-editor', ElmEditor);
customElements.define('selection-state', SelectionState);