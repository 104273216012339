"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Chart = void 0;
var EventDeserializer_1 = require("../Events/EventDeserializer");
var ChartValidation_1 = require("./ChartValidation");
var Zone_1 = require("./Zone");
var Chart = /** @class */ (function () {
    function Chart(chart) {
        this.name = chart.name;
        this.id = chart.id;
        this.key = chart.key;
        this.status = chart.status;
        this.tags = chart.tags;
        this.publishedVersionThumbnailUrl = chart.publishedVersionThumbnailUrl;
        this.draftVersionThumbnailUrl = chart.draftVersionThumbnailUrl || null;
        this.events = chart.events ? chart.events.map(function (event) { return new EventDeserializer_1.EventDeserializer().fromJson(event); }) : [];
        this.archived = chart.archived;
        if (chart.validation)
            this.validation = new ChartValidation_1.ChartValidation(chart.validation);
        this.venueType = chart.venueType;
        this.zones = chart.zones ? chart.zones.map(function (zone) { return Zone_1.Zone.fromJson(zone); }) : [];
    }
    return Chart;
}());
exports.Chart = Chart;
