"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Floor = void 0;
var Floor = /** @class */ (function () {
    function Floor(name, displayName) {
        this.name = name;
        this.displayName = displayName;
    }
    Floor.prototype.getName = function () {
        return this.name;
    };
    Floor.prototype.getDisplayName = function () {
        return this.displayName;
    };
    return Floor;
}());
exports.Floor = Floor;
