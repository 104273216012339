"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChartObjectInfo = void 0;
var helperFunctions_1 = require("../utilities/helperFunctions");
var IDs_1 = require("../Common/IDs");
var Floor_1 = require("../Common/Floor");
var ChartObjectInfo = /** @class */ (function () {
    function ChartObjectInfo(json) {
        this.label = json.label;
        this.labels = helperFunctions_1.HelperFunctions.labelCreator(json);
        this.ids = new IDs_1.IDs(json.ids.own, json.ids.parent, json.ids.section);
        this.categoryLabel = json.categoryLabel;
        this.categoryKey = json.categoryKey;
        this.entrance = json.entrance;
        this.objectType = json.objectType;
        this.section = json.section;
        this.capacity = json.capacity;
        this.bookAsAWhole = json.bookAsAWhole;
        this.leftNeighbour = json.leftNeighbour;
        this.rightNeighbour = json.rightNeighbour;
        this.distanceToFocalPoint = json.distanceToFocalPoint;
        this.numSeats = json.numSeats;
        this.isAccessible = json.isAccessible;
        this.isCompanionSeat = json.isCompanionSeat;
        this.hasRestrictedView = json.hasRestrictedView;
        this.zone = json.zone;
        this.floor = json.floor ? new Floor_1.Floor(json.floor.name, json.floor.displayName) : undefined;
    }
    return ChartObjectInfo;
}());
exports.ChartObjectInfo = ChartObjectInfo;
