"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CategoryUpdateParams = void 0;
var CategoryUpdateParams = /** @class */ (function () {
    function CategoryUpdateParams() {
    }
    CategoryUpdateParams.prototype.withLabel = function (label) {
        this.label = label;
        return this;
    };
    CategoryUpdateParams.prototype.withColor = function (color) {
        this.color = color;
        return this;
    };
    CategoryUpdateParams.prototype.withAccessible = function (accessible) {
        this.accessible = accessible;
        return this;
    };
    CategoryUpdateParams.prototype.serialize = function () {
        return {
            label: this.label,
            color: this.color,
            accessible: this.accessible
        };
    };
    return CategoryUpdateParams;
}());
exports.CategoryUpdateParams = CategoryUpdateParams;
