"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventReports = void 0;
var reportUtility_1 = require("../utilities/reportUtility");
var EventReports = /** @class */ (function () {
    function EventReports(client) {
        this.client = client;
    }
    EventReports.prototype.byStatus = function (eventKey, status) {
        if (status === void 0) { status = null; }
        return this.client.get(EventReports.reportUrl('byStatus', eventKey, status))
            .then(function (res) { return reportUtility_1.Utilities.createEventReport(res.data); });
    };
    EventReports.prototype.summaryByStatus = function (eventKey) {
        return this.client.get(EventReports.summaryReportUrl('byStatus', eventKey))
            .then(function (res) { return res.data; });
    };
    EventReports.prototype.deepSummaryByStatus = function (eventKey) {
        return this.client.get(EventReports.deepSummaryReportUrl('byStatus', eventKey))
            .then(function (res) { return res.data; });
    };
    EventReports.prototype.byObjectType = function (eventKey, objectType) {
        if (objectType === void 0) { objectType = null; }
        return this.client.get(EventReports.reportUrl('byObjectType', eventKey, objectType))
            .then(function (res) { return reportUtility_1.Utilities.createEventReport(res.data); });
    };
    EventReports.prototype.summaryByObjectType = function (eventKey) {
        return this.client.get(EventReports.summaryReportUrl('byObjectType', eventKey))
            .then(function (res) { return res.data; });
    };
    EventReports.prototype.deepSummaryByObjectType = function (eventKey) {
        return this.client.get(EventReports.deepSummaryReportUrl('byObjectType', eventKey))
            .then(function (res) { return res.data; });
    };
    EventReports.prototype.byCategoryLabel = function (eventKey, categoryLabel) {
        if (categoryLabel === void 0) { categoryLabel = null; }
        return this.client.get(EventReports.reportUrl('byCategoryLabel', eventKey, categoryLabel))
            .then(function (res) { return reportUtility_1.Utilities.createEventReport(res.data); });
    };
    EventReports.prototype.summaryByCategoryLabel = function (eventKey) {
        return this.client.get(EventReports.summaryReportUrl('byCategoryLabel', eventKey))
            .then(function (res) { return res.data; });
    };
    EventReports.prototype.deepSummaryByCategoryLabel = function (eventKey) {
        return this.client.get(EventReports.deepSummaryReportUrl('byCategoryLabel', eventKey))
            .then(function (res) { return res.data; });
    };
    EventReports.prototype.byCategoryKey = function (eventKey, categoryKey) {
        if (categoryKey === void 0) { categoryKey = null; }
        return this.client.get(EventReports.reportUrl('byCategoryKey', eventKey, categoryKey))
            .then(function (res) { return reportUtility_1.Utilities.createEventReport(res.data); });
    };
    EventReports.prototype.summaryByCategoryKey = function (eventKey) {
        return this.client.get(EventReports.summaryReportUrl('byCategoryKey', eventKey))
            .then(function (res) { return res.data; });
    };
    EventReports.prototype.deepSummaryByCategoryKey = function (eventKey) {
        return this.client.get(EventReports.deepSummaryReportUrl('byCategoryKey', eventKey))
            .then(function (res) { return res.data; });
    };
    EventReports.prototype.byLabel = function (eventKey, label) {
        if (label === void 0) { label = null; }
        return this.client.get(EventReports.reportUrl('byLabel', eventKey, label))
            .then(function (res) { return reportUtility_1.Utilities.createEventReport(res.data); });
    };
    EventReports.prototype.byOrderId = function (eventKey, orderId) {
        if (orderId === void 0) { orderId = null; }
        return this.client.get(EventReports.reportUrl('byOrderId', eventKey, orderId))
            .then(function (res) { return reportUtility_1.Utilities.createEventReport(res.data); });
    };
    EventReports.prototype.bySection = function (eventKey, section) {
        if (section === void 0) { section = null; }
        return this.client.get(EventReports.reportUrl('bySection', eventKey, section))
            .then(function (res) { return reportUtility_1.Utilities.createEventReport(res.data); });
    };
    EventReports.prototype.summaryBySection = function (eventKey) {
        return this.client.get(EventReports.summaryReportUrl('bySection', eventKey))
            .then(function (res) { return res.data; });
    };
    EventReports.prototype.deepSummaryBySection = function (eventKey) {
        return this.client.get(EventReports.deepSummaryReportUrl('bySection', eventKey))
            .then(function (res) { return res.data; });
    };
    EventReports.prototype.byZone = function (eventKey, zone) {
        if (zone === void 0) { zone = null; }
        return this.client.get(EventReports.reportUrl('byZone', eventKey, zone))
            .then(function (res) { return reportUtility_1.Utilities.createEventReport(res.data); });
    };
    EventReports.prototype.summaryByZone = function (eventKey) {
        return this.client.get(EventReports.summaryReportUrl('byZone', eventKey))
            .then(function (res) { return res.data; });
    };
    EventReports.prototype.deepSummaryByZone = function (eventKey) {
        return this.client.get(EventReports.deepSummaryReportUrl('byZone', eventKey))
            .then(function (res) { return res.data; });
    };
    EventReports.prototype.byAvailability = function (eventKey, availability) {
        if (availability === void 0) { availability = null; }
        return this.client.get(EventReports.reportUrl('byAvailability', eventKey, availability))
            .then(function (res) { return reportUtility_1.Utilities.createEventReport(res.data); });
    };
    EventReports.prototype.byAvailabilityReason = function (eventKey, availabilityReason) {
        if (availabilityReason === void 0) { availabilityReason = null; }
        return this.client.get(EventReports.reportUrl('byAvailabilityReason', eventKey, availabilityReason))
            .then(function (res) { return reportUtility_1.Utilities.createEventReport(res.data); });
    };
    EventReports.prototype.summaryByAvailability = function (eventKey) {
        return this.client.get(EventReports.summaryReportUrl('byAvailability', eventKey))
            .then(function (res) { return res.data; });
    };
    EventReports.prototype.summaryByAvailabilityReason = function (eventKey) {
        return this.client.get(EventReports.summaryReportUrl('byAvailabilityReason', eventKey))
            .then(function (res) { return res.data; });
    };
    EventReports.prototype.deepSummaryByAvailability = function (eventKey) {
        return this.client.get(EventReports.deepSummaryReportUrl('byAvailability', eventKey))
            .then(function (res) { return res.data; });
    };
    EventReports.prototype.deepSummaryByAvailabilityReason = function (eventKey) {
        return this.client.get(EventReports.deepSummaryReportUrl('byAvailabilityReason', eventKey))
            .then(function (res) { return res.data; });
    };
    EventReports.prototype.byChannel = function (eventKey, channel) {
        if (channel === void 0) { channel = null; }
        return this.client.get(EventReports.reportUrl('byChannel', eventKey, channel))
            .then(function (res) { return reportUtility_1.Utilities.createEventReport(res.data); });
    };
    EventReports.prototype.summaryByChannel = function (eventKey) {
        return this.client.get(EventReports.summaryReportUrl('byChannel', eventKey))
            .then(function (res) { return res.data; });
    };
    EventReports.prototype.deepSummaryByChannel = function (eventKey) {
        return this.client.get(EventReports.deepSummaryReportUrl('byChannel', eventKey))
            .then(function (res) { return res.data; });
    };
    EventReports.reportUrl = function (reportType, eventKey, filter) {
        if (filter === null || typeof filter === 'undefined') {
            return "/reports/events/".concat(encodeURIComponent(eventKey), "/").concat(reportType);
        }
        return "/reports/events/".concat(encodeURIComponent(eventKey), "/").concat(reportType, "/").concat(encodeURIComponent(filter));
    };
    EventReports.summaryReportUrl = function (reportType, eventKey) {
        return "/reports/events/".concat(encodeURIComponent(eventKey), "/").concat(reportType, "/summary");
    };
    EventReports.deepSummaryReportUrl = function (reportType, eventKey) {
        return "/reports/events/".concat(encodeURIComponent(eventKey), "/").concat(reportType, "/summary/deep");
    };
    return EventReports;
}());
exports.EventReports = EventReports;
